<template>
  <!--div class="acountapply">
    <a @click="moneyinfo=!moneyinfo"><img src="@/assets_mobile/img/icon_question.svg" /></a>
  </div-->
  <div class="state">현재 포인트가 보유머니로 전환되며, 최소 10,000P부터 신청 가능합니다.</div>
  <div class="topbox">
     <img src="@/assets_mobile/img/img-point.png" />
     <div class="appliwrite">
        <!--p class="outmoney">{{$t('front.point.pointInput')}}</p-->
        <input type="text" pattern="[0-9.,]+" class="numb" :placeholder="$t('front.point.pointInput')" v-model="changePoint"/>
        <i>P</i>
        <!--img src="@/assets_mobile/img/icon_delete.svg" /-->
     </div>
     <ul class="applihave">
       <li><img src="@/assets_mobile/img/icon_exclamat.png" /></li>
       <li>{{$t('front.point.leftPoint')}} :</li>
       <li class="">{{thousand(currentPoint.toString())}} P</li>
     </ul>
     <ul class="applimoneybtn">
       <li class="bga"><a @click="setPoint(10000)">+1{{$t('front.cash.man')}}P</a></li>
       <li class="bga"><a @click="setPoint(50000)">+5{{$t('front.cash.man')}}P</a></li>
       <li class="bgb"><a @click="setPoint(100000)">+10{{$t('front.cash.man')}}P</a></li>
       <li class="bgc"><a @click="setPoint('all')">{{$t('front.cash.all')}}</a></li>
     </ul>
  </div>
  <!--ul class="appliinput">
    <li>현재 잔여 포인트</li>
    <li><span>{{thousand(currentPoint.toString())}}</span>P</li>
  </ul>
  <ul class="appliinput">
    <li>전환 예정 포인트</li>
    <li><strong>{{thousand(changePoint.toString())}}</strong>P</li>
  </ul>
  <ul class="appliinput">
    <li>전환 후 잔여 포인트</li>
    <li><strong>{{thousand(remainPoint.toString())}}</strong>P</li>
  </ul>
  <ul class="appliinput">
    <li>전환 포인트 입력</li>
    <li></li>
  </ul>

  <ul class="moneybtnwrap">
    <li class="one" @click="setPoint(1000)"><a>1천P</a></li>
    <li class="one" @click="setPoint(5000)"><a>5천P</a></li>
    <li class="one" @click="setPoint(10000)"><a>1만P</a></li>
    <li class="two" @click="setPoint(50000)"><a>5만P</a></li>
  </ul>
  <ul class="moneybtnwrap">
    <li class="thr" @click="setPoint(100000)"><a>10만P</a></li>
    <li class="four" @click="setPoint(500000)"><a>50만P</a></li>
    <li class="fiv" @click="setPoint('all')"><a>전체</a></li>
    <li class="six" @click="setPoint(0)"><a>정정</a></li>
  </ul-->

  <div class="moneyinfowrap" v-if="moneyinfo">
    <div class="moneyinfoappli">
      <a class="close" @click="moneyinfo=!moneyinfo"><img src="@/assets_mobile/img/icon_delete.svg" /></a>
      <h3 class="applih">{{$t('front.point.changePoint')}}</h3>
      <p class="applip bb0 pb0">{{$t('front.point.changePointA')}} <span class="">1,000</span>P {{$t('front.point.changePointB')}}</p>
    </div>
  </div>
  <div class="btnwrap">
    <a class="ok gamebetmore" @click="onSubmit">{{$t('front.cash.moneyCheckListL')}}</a>
  </div>
  <div class="moneyread">
    <h3>NOTICE</h3>
    <ul>
      <li><span>1. {{$t('front.point.moneyPoint')}}</span></li>
      <li><span>2. {{$t('front.point.limitPoint')}}</span></li>
      <li><span>3. {{$t('front.cash.moneyCheckListD')}}</span></li>
    </ul>
  </div>
</template>

<script>

import pointUse from '@/views/member/mypage/point/use'

export default {
  name: 'mobilePointUse',
  data () {
    return {
      moneyinfo: false
    }
  },
  components: {
  },
  mixins: [
    pointUse
  ]
}
</script>

<style scoped src="@/styles_mobile/betlist.css"></style>
<style scoped src="@/styles_mobile/money.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
